import React, { ReactElement } from "react"
import Data from "../data/kredit-trotz"
import ApplyContainer from "../hoc/apply"
import CommonPointsContainer from "../hoc/common-points"
import HelmetContainer from "../hoc/helmet"
import DefaultLayout from "../layouts/default"
import "./style.css"
function Index({}: any): ReactElement {
  return (
    <>
      <DefaultLayout>
        <HelmetContainer pageTitle="Kredit Trotz" />
        <ApplyContainer title={Data.formTitle} subTitle={Data.formSubTitle} />
        <CommonPointsContainer data={Data} />
      </DefaultLayout>
    </>
  )
}

export default Index
